import React, { forwardRef } from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: string;
  className?: string;
  labelClassName?: string;
}

const CustomInput = forwardRef<HTMLInputElement, InputProps>(
  ({ label, errorMessage, className, labelClassName, ...props }, ref) => {
    return (
      <div className="flex flex-col mb-4">
        {label && (
          <label
            htmlFor={props.id}
            className={`${labelClassName} block text-sm font-medium mb-2 `}
          >
            {label}
          </label>
        )}
        <input
          ref={ref}
          className={`py-3 px-4 block w-full border-dark/20 rounded-lg text-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-dark dark:border-neutral-700 dark:text-light ${className}`}
          {...props}
        />
        {errorMessage && (
          <p className="mt-1 text-xs text-red-500">{errorMessage}</p>
        )}
      </div>
    );
  }
);

CustomInput.displayName = "CustomInput";

export default CustomInput;
